<template>
  <div>
    <el-table stripe border v-loading="loadData" :data="registryList.content">
      <el-table-column
        label="Название предприятия"
        prop="egrDTO.fio"
      ></el-table-column>
      <el-table-column
        label="Полное название предприятия"
        prop="egrDTO.companyName"
      ></el-table-column>
      <el-table-column label="Адрес" prop="address">
        <template slot-scope="scope">
          <p><strong>Адрес из ЕГР: </strong> {{ scope.row.egrDTO.address }}</p>
          <p><strong>Адрес из Реестров: </strong> {{ scope.row.address }}</p>
        </template>
      </el-table-column>
      <el-table-column label="Телефон" prop="phone">
        <template slot-scope="scope">
          <p>
            <strong> Телефон из ЕГР: </strong>{{ scope.row.egrDTO.phoneNumber }}
          </p>
          <p><strong> Телефон из Реестров: </strong> {{ scope.row.phone }}</p>
        </template>
      </el-table-column>
      <el-table-column width="210" label="Использует собственные" prop="useOwn">
        <template slot-scope="scope">
          <ul style="padding-right: 5px !important">
            <li :key="index" v-for="(item, index) in scope.row.useOwn">
              {{ item }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column width="210" label="Принимает от других" prop="useOthers">
        <template slot-scope="scope">
          <ul style="padding-right: 5px !important">
            <li :key="index" v-for="(item, index) in scope.row.useOthers">
              {{ item }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column label="Отходы" width="210" prop="wastes">
        <template slot-scope="scope">
          <ul style="padding-right: 5px !important">
            <li :key="index" v-for="(item, index) in scope.row.wastes">
              {{ item }}
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="registryList.size"
      :current-page.sync="registryList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="registryList.totalElements"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AXIOS } from "@/AXIOS/http-common";

export default {
  name: "useRegistryTable",
  data() {
    return {
      loadData: true,
      useRegistry: [{}],
      pageOptions: {
        page: 0,
        size: 20,
      },
    };
  },

  computed: {
    ...mapGetters({
      registryList: "GETREGISTRYLIST",
    }),
  },

  methods: {
    async handleSizeChange(val) {
      await this.getAllRegistries({ page: 0, size: val });
      this.useRegistry = await this.formedRegistryList();
    },

    async handleCurrentChange(val) {
      await this.getAllRegistries({
        page: val - 1,
        size: this.registryList.size,
      });
      this.useRegistry = await this.formedRegistryList();
    },

    async getAllRegistries(data) {
      await this.$store.dispatch("getAllRegistries", data);
    },

    async formedRegistryList() {
      let data = this.registryList;
      for (let item of data.content) {
        console.log(item);
        await this.getEgrById(item.egrId.egrId).then((response) => {
          item.egr = response.data;
        });

        await this.getWastesByIds(item.wastes_id).then((response) => {
          item.wastes = response.data;
        });
      }
      return data;
    },

    async getWastesByIds(ids) {
      console.log(ids);
      return await AXIOS.get(
        "/classifiers/ss01dictionary/findByWastes?wasteIds=" + ids
      );
    },

    async getEgrById(id) {
      return await AXIOS.get("/classifiers/egr/get?egrId=" + id);
    },
  },

  async mounted() {
    await this.getAllRegistries(this.pageOptions);
    this.loadData = false;
  },
};
</script>

<style scoped></style>
