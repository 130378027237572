<template>
  <div>
    <h2>Существующие объекты по использованию отхода</h2>

    <use-registry-table></use-registry-table>
  </div>
</template>

<script>
import UseRegistryTable from "@/components/admin/registries/useRegistry/useRegistryTable";
export default {
  name: "useRegistryMain",
  components: { UseRegistryTable },
};
</script>

<style scoped></style>
