<template>
  <div>
    <use-registry-main></use-registry-main>
  </div>
</template>

<script>
import UseRegistryMain from "@/components/admin/registries/useRegistry/useRegistryMain";
export default {
  name: "useRegistry",
  components: { UseRegistryMain },
};
</script>

<style scoped></style>
