import { render, staticRenderFns } from "./useRegistryTable.vue?vue&type=template&id=479147cb&scoped=true&"
import script from "./useRegistryTable.vue?vue&type=script&lang=js&"
export * from "./useRegistryTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479147cb",
  null
  
)

export default component.exports